import "bootstrap/dist/css/bootstrap.min.css";
import "react-dice-complete/dist/react-dice-complete.css";
import "./App.css";
import { useState } from "react";
import ReactDice from "react-dice-complete";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Navbar from "react-bootstrap/Navbar";
import igLogo from "./img/instagram-brands.svg";
import running from "./img/runner.svg";
import plank from "./img/plank.svg";
import squats from "./img/squats.svg";
import burpees from "./img/workout.svg";
import pushup from "./img/workout(1).svg";

function App() {
  const [dice, setDice] = useState();
  const [currentRoll, setCurrentRoll] = useState("running");
  const [challenges, setChallenges] = useState({
    running: "Noch nicht ausgewürfelt",
    plank: "Noch nicht ausgewürfelt",
    burpees: "Noch nicht ausgewürfelt",
    squats: "Noch nicht ausgewürfelt",
    pushup: "Noch nicht ausgewürfelt",
  });
  const [numbers, setNumbers] = useState();

  const createRandomNumbers = () => {
    const numbers = [
      Math.floor(Math.random() * 6) + 1,
      Math.floor(Math.random() * 6) + 1,
    ];
    setNumbers(numbers);
    return numbers;
  };

  const calculateChallenge = () => {
    //trackEvent({ category: "roll", action: "click-event" });
    let amount;
    switch (currentRoll) {
      case "running":
        const distance = numbers[0] * numbers[1];
        setChallenges({ ...challenges, running: `${distance} km` });
        setCurrentRoll("burpees");
        break;
      case "plank":
        const seconds = numbers[1] + numbers[0];
        setChallenges({ ...challenges, plank: `${seconds} Min` });
        setCurrentRoll(false);
        break;
      case "burpees":
        amount = (numbers[0] + numbers[1]) * 10;
        setChallenges({ ...challenges, burpees: amount });
        setCurrentRoll("pushup");
        break;
      case "squats":
        amount = (numbers[0] + numbers[1]) * 10;
        setChallenges({ ...challenges, squats: amount });
        setCurrentRoll("plank");
        break;
      case "pushup":
        amount = (numbers[0] + numbers[1]) * 10;
        setChallenges({ ...challenges, pushup: amount });
        setCurrentRoll("squats");
        break;
      default:
        break;
    }
  };

  return (
    <>
      {" "}
      <div className="bg-dark py-5">
        <Container>
          <Row className="mb-3">
            <Col>
              <h1>Bockdown Challenge:</h1>
              <h4>
                <a href="#intro">Was ist das?</a>
              </h4>
            </Col>
          </Row>
          <Row className="justify-content-center mb-5">
            <Col className="mx-auto" align="center">
              <ReactDice
                numDice={2}
                dieSize={100}
                margin={30}
                dotColor="black"
                faceColor="white"
                outline
                disableIndividual
                rollDone={() => calculateChallenge()}
                ref={(dice) => setDice(dice)}
              />
              <Button
                className="mt-3"
                variant="light"
                size="large"
                onClick={() => dice.rollAll(createRandomNumbers())}
                disabled={currentRoll === false}
              >
                <h2 className="mb-0"> Würfeln</h2>
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row className="justify-content-center font-dark">
                <Col md={6}>
                  <Card>
                    <Card.Body className="py-1">
                      <Row>
                        <Col className="col-auto" md={2}>
                          <img width="48" src={running} alt="running" />
                        </Col>
                        <Col className="my-auto col-auto">
                          <h5 className="mb-0">Running:</h5>
                        </Col>
                        <Col className="my-auto text-right mr-3">
                          <h5 className="mb-0">{challenges.running}</h5>
                        </Col>
                      </Row>
                      <hr className="my-1"></hr>
                      <Row>
                        <Col className="col-auto" md={2}>
                          <img width="48" src={burpees} alt="burpees" />
                        </Col>
                        <Col className="my-auto col-auto">
                          <h5 className="mb-0">Burpees:</h5>
                        </Col>
                        <Col className="my-auto text-right mr-3">
                          <h5 className="mb-0">{challenges.burpees}</h5>
                        </Col>
                      </Row>
                      <hr className="my-1"></hr>
                      <Row>
                        <Col className="col-auto" md={2}>
                          <img width="48" src={pushup} alt="pushup" />
                        </Col>
                        <Col className="my-auto col-auto">
                          <h5 className="mb-0">Push-Ups:</h5>
                        </Col>
                        <Col className="my-auto text-right mr-3">
                          <h5 className="mb-0">{challenges.pushup}</h5>
                        </Col>
                      </Row>
                      <hr className="my-1"></hr>
                      <Row>
                        <Col className="col-auto" md={2}>
                          <img width="48" src={squats} alt="squats" />
                        </Col>
                        <Col className="my-auto col-auto">
                          <h5 className="mb-0">Squats:</h5>
                        </Col>
                        <Col className="my-auto text-right mr-3">
                          <h5 className="mb-0">{challenges.squats}</h5>
                        </Col>
                      </Row>
                      <hr className="my-1"></hr>
                      <Row>
                        <Col className="col-auto" md={2}>
                          <img width="48" src={plank} alt="plank" />
                        </Col>
                        <Col className="my-auto col-auto">
                          <h5 className="mb-0">Planking: </h5>
                        </Col>
                        <Col className="my-auto text-right mr-3">
                          <h5 className="mb-0">{challenges.plank}</h5>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <Container>
          <Row>
            <Col></Col>
          </Row>
        </Container>
      </div>
      <div className="bg-dark py-5">
        <Container id="intro">
          <Row className="justify-content-center justify">
            <Col>
              <h1 className="mb-0">bock|down, der</h1>
              <h3>['b​ɔkda​ʊn] Substantiv</h3>
              <h6>
                [1] konstantes Ausbleiben des inneren Bedürfnisses sich
                sportlich zu betätigen. Häufig im Zusammenhang mit dem
                verwandten Wort "lockdown" verwendet. <br></br>
                <br></br>
                [2] mit Bezug auf den am 2. November beginnenden "lockdown
                light": vier Wochen Challenge, bei der zwei Würfel den
                Tagesumfang der Sportbetätigungen Laufen, Liegestütz mit
                Hochstrecksprung, Liegestütz, Kniebeugen und Unterarmstütz
                bestimmen
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-5 bg-dark">
        <Container>
          <Row>
            <Col>
              <h2>
                <strong>SPIELREGELN</strong>
              </h2>
              <h6>
                Für einen Zeitraum von 4 Wochen werden jeden Morgen mehrfach 2
                Würfel geworfen, um herauszufinden, wie viele, Kilometer,
                Burpees, Push-ups, Squats und Planks an dem Tag hinter sich
                gebracht werden müssen
              </h6>
              <br></br>
              <br></br>
              <h2>JOKER</h2>
              <h6>
                Um die Challenge möglichst verletzungsfrei durchzustehen sind
                die folgenden Joker geplant
                <br></br>
                <br></br>
                <ul>
                  <li>
                    <strong>j1) KM-Kontostand</strong>
                    <br></br>Kilometer dürfen gesammelt oder geparkt werden. Der
                    Kontostand darf bist zum Ende der Challenge keine geparkten
                    KM mehr aufweisen.
                  </li>
                  <li>
                    <strong>j2) 50/50 Joker</strong>
                    <br></br>Reduktion des KM-Umfangs um 50%.
                  </li>
                  <li>
                    <strong>j3) Telefonjoker</strong>
                    <br></br>Es darf jemand angerufen werden, der alle oder
                    einen Teil der Kilomenter abnimmt.
                  </li>
                  <li>
                    <strong>j4) Publikumsjoker</strong>
                    <br></br>Die Community entscheidet, was alternativ gelaufen
                    wird.
                  </li>
                </ul>
              </h6>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-5">
        <Container>
          <Row>
            <Col md={4} align="center">
              <Image
                src="Eugen_circle_300px.png"
                height="200"
                className="m-auto"
              />
            </Col>
            <Col md={8} className="my-auto">
              <h4 className="mb-3">
                Schön, dass du dich hier auf der Bockdown-Challenge verirrt
                hast.
              </h4>
              <h6 className="justify">
                Ich bin Eugen und habe dieses Jahr extrem wenig Motivation
                gefunden, um regelmäßig Laufen zu gehen und Sport zu treiben.{" "}
                <strong>DAS ALLES HAT JETZT ABER EIN ENDE!</strong> Denn mit der
                Bockdown-Challenge bestimmen zwei (digitale) Würfel, wie mein
                sportlicher Alltag aussieht und wie kaputt ich abends sein
                werde. Ja, so einach kann's gehen. Mach also gerne mit und lass
                das Glück entscheiden, wie fertig du morgen sein wirst.
              </h6>
              <a href="https://www.instagram.com/eugen.fink/" target="blank">
                <img width="16" src={igLogo} alt="IG" />
              </a>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="">
        <Navbar className=" bg-dark" expand="lg" style={{ overflow: "hidden" }}>
          <Container>
            <Navbar.Brand>
              <h4 className="d-inline-block mb-0" style={{ color: "grey" }}>
                Powered by{"  "}
                <a href="https://stackocean.com" target="blank">
                  stackOcean.com
                </a>
              </h4>
            </Navbar.Brand>
            <Navbar.Brand style={{ color: "white", fontSize: "0.8rem" }}>
              <a href="https://stackocean.com/imprint.html" target="blank">
                Impressum
              </a>
              |
              <a href="https://stackocean.com/privacy.html" target="blank">
                Datenschutz
              </a>
            </Navbar.Brand>
            <Navbar.Brand style={{ color: "grey", fontSize: "0.8rem" }}>
              <p className="mb-0">
                Icons made by{" "}
                <a
                  href="https://www.flaticon.com/free-icon/workout_3043171"
                  title="ultimatearm"
                >
                  ultimatearm
                </a>{" "}
                from{" "}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com
                </a>
              </p>
            </Navbar.Brand>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default App;
